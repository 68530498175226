import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../../utils/useDeviceType';
import PocketBase from 'pocketbase';
import { Button } from '../../components/Button';

import'./styles.css'
import MenuModal from "../../components/MenuModal";

const GameSelector = ( ) => {
    const navigate = useNavigate();
    const deviceType = useDeviceType()
    const pb = new PocketBase('https://auth.dillo.ar/');

    const handleOnClick = (gameType) => {
        navigate(`/${gameType}`)
    }

    return (
        <div className='selector-container '>
            <MenuModal/>
            <div>
                <img 
                    src="/dillo-brand.png"
                    width='211px'
                />
            </div>
            <label className='selector-text'>Elige la modalidad de juego</label>
            <div className={`btn-selector-container ${deviceType !== 'mobile' && 'desktop-container'}`}>
                <Button 
                    onClick={() => handleOnClick('deletrear')} 
                    buttonType='primary'
                    text='Deletrear'
                /> 
                <Button 
                    onClick={() => handleOnClick('palabras')} 
                    buttonType='primary'
                    text='Palabras'
                />
            </div>
        </div>
    );
    };

export default GameSelector;